.FormTitle {
    font-weight: 600;
    font-size: 35px;
}

.CheckboxTitle {
    font-weight: 500;
    font-size: 15px;
    margin-top: 3vh;
}

.SignupFormStyle {
    color: white;
    margin-top: 5vh;
    width: 50vw;
}

.LabelStyle {
    font-weight: 500;
    font-size: 15px;
}

.CheckboxLabelStyle {
    font-weight: 500;
    font-size: 15px;
}

.EmailInput {
    height: 30px;
    width: 100%;
    margin-top: 10px;
    background-color: #138E83;
    border: 1px solid;
    border-radius: 5px;
    border-color: white;
    box-shadow: none;
}

input {
    margin-left: 0px;
    color: white;
}

.SignupButtonStyle {
    margin-top: 5vh;
    height: 40px;
    width: 100px;
    background-color: #138E83;
    border: 1px solid;
    border-radius: 5px;
    border-color: white;
    box-shadow: none;
    color: white;
    cursor: pointer;
}

input[type='radio'], label{
   display:inline;
   vertical-align:sub;
}

.CheckboxStyle {
    margin-right: 20px;
    margin-bottom: 20px;
}

input.CheckboxStyle {
    height: 20px;
    width: 20px;
    background: #138E83;
}

.inputSpacing {
    margin-top: 3vh;
}

.companyInput {
    display: none;
}

.flexStyle {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.flexChild {
    flex: 10;
}

@media screen and (max-width: 600px) {
    .SignupFormStyle {
        width: 80vw;
    }
}
