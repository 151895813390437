.Header{
    color: #19DDB2;
    font-size: 56px;
    font-weight: 700;
}

.PreHeader {
    color: white;
    font-size: 24px;
    font-weight: 500;
}

.InspoTextStyle {
    align-content: flex-start;
    text-align: left;
}

@media screen and (max-width: 600px) {
    .Header {
        font-size: 40px;
    }

    .PreHeader {
        font-size: 20px;
    }
}
