.HowTextStyle {
    color: white;
    text-align: left;
    padding-top: 1vh;
}

.HIWHeader {
    color: #19DDB2;
    font-size: 50px;
    font-weight: 300;
}

.HIWDiagramStyle {
    width: auto;
    padding-top: 2%;
}

.ConsumerDiagram {
    height: 70vh;
}

.MerchantDiagram {
    height: 50vh;
    margin-top: 10px;
}

@media screen and (max-width: 600px) {
    .ConsumerDiagram {
        height: 35vh;
    }

    .MerchantDiagram {
        height: 25vh;
    }

    .HIWHeader {
        font-size: 40px;
    }
}
