.App {
  text-align: center;
  background-color: #138E83;
  min-height: 100vh;
  display: flex;
  position: relative;
  flex: auto;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.App-logo {
  height: 10vmin;
  width: 10vmin;
  pointer-events: none;
  position: relative;
  padding-top: 15px;
  padding-left: 15px;
}

.App-header {
  min-height: 15vh;
  display: flex;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.PageSize {
  min-height: 100vh;
}

/* =============== TOP STYLES ==================== */
.Top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: 85vh;
  background-color: #138E83;
  overflow-wrap: break-word;
  flex: auto;
}

.TopItem {
  border: medium solid;
}

.TopBodyLeft {
  overflow-wrap: break-word;
  flex:auto;
  flex-grow: 1;
  margin-left: 5%;
  margin-right: 5%;
}

.TopBodyRight {
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 2;
  margin-right: 5%;
  margin-left: 5%;
}

/* =============== MID STYLES ==================== */
.Mid {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 100vh;
}

.MidItem {
  border: medium solid;

}

.MidHeader {
  display: flex;
  padding-top: 1vh;
  justify-content: center;
}

.MidBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* =============== BOTTOM STYLES ==================== */
.Bottom {
  display: flex;
  flex-direction: column;
  background-color: #19DDB2;
  flex-wrap: wrap;
  align-content: center;
  overflow-wrap: break-word;
}

.BottomContent {
  text-align: left;
}

.BottomLine {
  border-top: 0.1px solid white;
  width: 100%;
  margin-top: 7vh;
}

.CopyrightStyle {
  color: white;
  font-size: 10px;
}


@media screen and (max-width: 600px) {
  .Top {
    flex-wrap: wrap;
  }

  .Mid {
    min-height: 80vh;
  }

  .App-header {
    min-height: 10vh;
  }
}
