.PersonBarStyle{
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: center;
    color: #19DDB2;
    text-transform: uppercase;
    column-gap: 100px;
    margin-top: 5px;
}

.PersonBarButton {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    font-size: 16px;
    color: #19DDB2;
}

.Consumer {
    order: 1;
    font-weight: 400;
}

.Merchant {
    order: 2;
    font-weight: 400;
}

.Underline {
    text-decoration: underline 2px;
    text-underline-offset: 1vh;
}
