.SingleImage {
    height: 75vh;
    width: auto;
}

@media screen and (max-width: 600px) {
    .SingleImage {
        height: 55vh;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
}
